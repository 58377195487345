
const routeList = [
  {
    name: '登录',
    path: '/login',
    component: () => import("@/pages/Login/index"),
    meta: {
      hideMenu: true
    }
  },
  {
    name: '打印',
    path: '/print',
    component: () => import("@/pages/Print/index"),
    meta: {
      hideMenu: true
    }
  },
  {
    name: '打印单词',
    path: '/printWord',
    component: () => import("@/pages/PrintWord/index"),
    meta: {
      hideMenu: true
    }
  },
  {
    path: '/',
    name: '主页',
    component: () => import("@/pages/Home/index"),
    icon: 'el-icon-s-platform'
  },
  {
    path: '/home/details',
    component: () => import("@/pages/HomeDetails/index"),
    name: '首页详情',
    meta: {
      hideMenu: true
    }
  },
  // {
  //   name: '通知公告',
  //   path: '/notice',
  //   component: () => import("@/pages/Notice/index"),
  //   icon: 'el-icon-bell'
  // },
  {
    path: '/manage',
    name: '机构管理',
    component: () => import("@/pages/Manage/index"),
    icon: 'el-icon-school',
    children: [
    {
      name: '机构列表',
      path: '/manage/school',
      component: () => import("@/pages/Manage/School/index"),
      icon: 'el-icon-school'
      },
      {
        name: '开户申请',
        path: '/manage/apply',
        component: () => import("@/pages/Manage/Apply/index"),
        icon: 'el-icon-office-building'
      },
      {
        name: '俱乐部管理',
        path: '/manage/club',
        component: () => import("@/pages/Manage/Club/index"),
        icon: 'el-icon-menu'
      }
    ]
  },
  {
    path: '/user/manage',
    name: '用户管理',
    component: () => import("@/pages/Manage/index"),
    icon: 'el-icon-user',
    children: [{
      name: '学员管理',
      path: '/manage/student',
      component: () => import("@/pages/Manage/Student/index"),
      icon: 'el-icon-s-custom',
    }, {
      name: '教练管理',
      path: '/manage/teacher',
      component: () => import("@/pages/Manage/Teacher/index"),
      icon: 'el-icon-user-solid',
    }, 
    {
      name: '退费审批',
      path: '/manage/refund',
      component: () => import("@/pages/Manage/Refund/index"),
      icon: 'el-icon-office-building'
    }
    // {
    //   name: '机构升级申请',
    //   path: '/manage/applyUpdate',
    //   component: () => import("@/pages/Manage/ApplyUpdate/index"),
    //   icon: 'el-icon-office-building'
    // }
    ] 
  },
  {
    name: '预约管理',
    path: '/appointment/list',
    component: () => import("@/pages/Appointment/index"),
    icon: 'el-icon-time'
  },
  {
    name: '内容管理',
    path: '/course/list',
    component: () => import("@/pages/Course/List/index"),
    icon: 'el-icon-notebook-1',
  },
  {
    path: '/record',
    name: '数据统计',
    component: () => import("@/pages/Record/index"),
    icon: 'el-icon-notebook-2',
    children: [{
      name: '上课统计',
      path: '/record/list',
      component: () => import("@/pages/Record/List/index"),
      icon: 'el-icon-chat-dot-round'
    }, {
      name: '测试统计',
      path: '/record/vocabularyTest',
      component: () => import("@/pages/Record/VocabularyTest/index"),
      icon: 'el-icon-chat-dot-round'
      },
      // {
      // name: '机构充值统计',
      // path: '/record/order/school',
      // component: () => import("@/pages/Record/Order/Organization/index"),
      // icon: 'el-icon-document-copy'
      // },
      // {
      //   name: '机构交易记录',
      //   path: '/record/order/transaction',
      //   component: () => import("@/pages/Record/Order/OrganizationRecord/index"),
      //   icon: 'el-icon-document-copy'
      // },
      {
        name: '学员充值统计',
        path: '/record/order/student',
        component: () => import("@/pages/Record/Order/index"),
        icon: 'el-icon-document-copy'
      },
      {
        name: '课耗收入统计',
        path: '/record/order/incomeAll',
        component: () => import("@/pages/Record/incomeAll/index"),
        icon: 'el-icon-document-copy'
      },
      {
        name: '分成数据详情',
        path: '/record/order/incomeAll/record',
        component: () => import("@/pages/Record/incomeAll/record"),
        icon: 'el-icon-document-copy',
        meta: {
          hideMenu: true
        }
      },
      // {
      //   name: '机构分成统计',
      //   path: '/record/commission',
      //   component: () => import("@/pages/Record/Commission/index"),
      //   icon: 'el-icon-document-copy'
      // },
      // {
      //   name: '课耗收入',
      //   path: '/record/order/income',
      //   component: () => import("@/pages/Record/Income/index"),
      //   icon: 'el-icon-document-copy'
      // },
      {
        name: '学员转正奖励',
        path: '/record/order/StudentRewards',
        component: () => import("@/pages/Record/StudentRewards/index"),
        icon: 'el-icon-document-copy'
      }
    ]
  },
  
  // {
  //   path: '/course',
  //   name: '教学相关',
  //   component: () => import("@/pages/Course/index"),
  //   icon: 'el-icon-receiving',
  //   children: [{
  //     name: '课程管理',
  //     path: '/course/list',
  //     component: () => import("@/pages/Course/List/index"),
  //     icon: 'el-icon-notebook-1',
  //   }],
  // },
  {
    path: '/salary',
    name: '薪资管理',
    component: () => import("@/pages/Salary/index"),
    icon: 'el-icon-postcard',
    children: [{
      name: '教练薪资设置',
      path: '/salary/school',
      component: () => import("@/pages/Salary/School/index"),
      icon: 'el-icon-coin'
    }, 
    // {
    //   name: '教练薪资管理',
    //   path: '/salary/teacher',
    //   component: () => import("@/pages/Salary/Teacher/index"),
    //   icon: 'el-icon-coin'
    // }, 
    {
      name: '教练薪资统计',
      path: '/salary/computation',
      component: () => import("@/pages/Salary/Computation/index"),
      icon: 'el-icon-bank-card'
    }]
  }
]

export default routeList