import Vue from 'vue'
import Vuex from 'vuex'
import routeList from './menu'
Vue.use(Vuex)

const store = new Vuex.Store({
  state() {
    return {
      menuList: [],
      routerRecord: [],
      organization: {},
      userInfo: {},
      enAssessResult:{}
    }
  },

  getters: {
    getMenuList(state) {
      return state.menuList
    },

    routeList() {
      return routeList
    },

    getRouterRecord(state) {
      return state.routerRecord
    },
    enAssessResult(state) {
      return state.enAssessResult
    }
  },

  mutations: {
    setMenuList(state, menuList) {
      state.menuList = menuList
    },

    setRouterRecord(state, routerItem) {
      const findIndex = state.routerRecord.findIndex(item => item.path == routerItem.path)
      if (findIndex > -1) {
        return
      }
      state.routerRecord.push(routerItem)
    },

    putRouterRecord(state, index) {
      state.routerRecord.splice(index, 1)
    },
    clearRouterRecord(state) {
      state.routerRecord = []
    },
    setOrganization(state,orgData) {
      state.organization = orgData
    },
    setUserInfo(state,userInfo) {
      state.userInfo = userInfo
    },
    setEnAssessResult(state,enAssessResult) {
      state.enAssessResult = enAssessResult
    }
  }
})
export default store