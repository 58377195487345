import axios from 'axios';
import Vue from 'vue'
console.log(axios)
const service = axios.create({
  baseURL: '/api',
  timeout: 15000
});

axios.defaults['baseURL'] = 'http://manage.xiaodubanxue.com/manage'
service.interceptors.request.use(
  config => {
    // 在请求发送之前对config进行一些处理，例如添加token到headers中等
    if (config.url !== '/acc/login') {
      const token = window.sessionStorage.getItem('token')
      if (!token && config.url.indexOf('anti/forget') == -1) {
        Vue.prototype.$toast.error('请退出重新登录')
        window.localStorage.clear()
        window.sessionStorage.clear()
        return
      }
      config.headers['third-admin-token'] = token
    }
    return config;
  },
  error => {
    if (!window.sessionStorage.getItem('token')) {
      Vue.prototype.$toast.error('请退出重新登录')
      window.localStorage.clear()
      window.sessionStorage.clear()
    }
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const res = response.request.responseType == 'blob' ? response : response.data;
    if (res.code == 200001) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      this.$router.replace('/login')
    }
    // 在响应返回时对res进行一些处理，例如判断返回的状态码等
    return res;
  },
  error => {
    console.log('err' + error);
    if (error.response.status === 504) {
      Vue.prototype.$toast.error('504_GATEWAY_TIMEOUT')
      return Promise.reject(error.response)
    }
    if (error.response.status === 400) {
        Vue.prototype.$toast.error('400_BAD_REQUEST')
        return Promise.reject(error.response)
    } 
    if (error.response.status === 401) {
        Vue.prototype.$toast.error('401_UNAUTHORIZED')
        return Promise.reject(error.response)
    } 
    if (error.response.status === 403) {
        Vue.prototype.$toast.error('403_UNAUTHORIZED')
        return Promise.reject(error.response)
    }
    if (error.response.status === 404) {
        Vue.prototype.$toast.error('404_NOT_FOUND')
        return Promise.reject(error.response)
    }  
    if (error.response.status === 408) {
        Vue.prototype.$toast.error('408_REQUEST_TIMEOUT')
        return Promise.reject(error.response);
    } 
    if (error.response.status === 500) {
        Vue.prototype.$toast.error('500_INTERNAL_SERVER_ERROR')
        return Promise.reject(error.response)
    } 
    if (error.response.status === 502) {
        Vue.prototype.$toast.error({title: '502_BAD_GATEWAY'})
        return Promise.reject(error.response)
    } 
    if (error.response.status === 503) {
        Vue.prototype.$toast.error('503_SERVICE_UNAVAILABLE')
        return Promise.reject(error.response)
    } 
    return Promise.reject(error);
  }
);

export default service;