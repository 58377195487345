<template>
  <div id="app">
    <el-container v-if="($route.path !== '/login') && ($route.path !== '/print') && ($route.path !== '/printWord')">
      <el-header class="app-el-header">
        <el-row class="app-el-header-logo">
          <img src="https://cdn.auoktalk.com/cdn/kid-mp/accompany/back-xiaodu-logo.jpg" alt="">
        </el-row>
        <el-row class="app-el-header-tab">
          <HeadTab />
        </el-row>
        <el-row class="app-el-header-login">
          <h3>
            <div>
              <i class="el-icon-user" style="margin-right:5px;"></i>
              <!-- <span>{{organization && organization.schoolCode}}</span> -->
              <span>{{organization && organization.schoolName}}</span>
              
            </div>
            <el-button v-if="organization.schoolType == 0" type="primary" size="mini" @click="handleChange">切换俱乐部账号</el-button>
            <el-button  type="danger" size="mini" @click="handleLogout">退出</el-button>
          </h3>
        </el-row>
      </el-header>
      <el-container>
        <div class="app-aside">
          <el-menu class="el-menu" background-color="#32323a" :unique-opened="true" :default-active="$route.path"
            text-color="#ffffff" router>
            <SlideMenu />
          </el-menu>
        </div>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <router-view v-else />
  </div>
</template>

<script>
import SlideMenu from '@/components/Menu/index.vue'
import HeadTab from '@/components/TabNav/index.vue'
import { mapGetters } from 'vuex'
export default {
  name: "App",
  data() {
    return {
      ...mapGetters,
      organization: JSON.parse(sessionStorage.getItem('organization')),
      adminUser: JSON.parse(sessionStorage.getItem('adminUser'))
    }
  },
  components: {
    SlideMenu,
    HeadTab
  },
  methods: {
    ignoreEmptyPath(list) {
      if (Array.isArray(list)) {
        let array = []
        for (let item of list) {
          if (!item.component && item.children && item.children.length) {
            array.push(...item.children)
          } else {
            array.push(item)
          }
        }
        return array
      }
    },
    handleChange() {
      this.$confirm('确定要切换吗？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$axios.get("/acc/login/change/club/account", { });
        if (res.code == 1 && res.data) {
          this.$store.state.userInfo = res.data
          window.sessionStorage.setItem('token', res.data.token)
          window.localStorage.setItem('organization', JSON.stringify(res.data.organization))
          window.sessionStorage.setItem('organization',JSON.stringify(res.data.organization))
          window.localStorage.setItem('adminUser', JSON.stringify(res.data.adminUser))
          window.sessionStorage.setItem('adminUser', JSON.stringify(res.data.adminUser))
          const redirect = this.$route.query.redirect
          this.$store.commit("clearRouterRecord",[])
          this.$store.commit("setOrganization",res.data.organization)
          this.$store.commit("setUserInfo",res.data.adminUser)
          if (redirect) {
            this.$router.push(redirect)
          } else {
            this.$router.go(0)
            this.$router.push('/')
          }
          return
        }
        this.$message.error(res.msg || res.message || '登录失败，请稍后重试')
      }).catch(() => {
      });
    },
    handleLogout() {
      this.$confirm('确定要退出登录吗？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.localStorage.clear()
        window.sessionStorage.clear()
        this.$router.replace('/login')
        this.$router.go(0)
      }).catch(() => {
      });
    }
  },
  watch:{
    "$store.state.organization" : {
      handler(newVal,oldVal) {
        this.organization = newVal.schoolName ? newVal : JSON.parse(sessionStorage.getItem('organization'))
      },
      deep: true
    },
    "$store.state.userInfo" : {
      handler(newVal,oldVal) {
         this.adminUser = newVal
      },
      deep: true
    }
  },
  created() {
    document.title = '小度伴学公司'
    console.log(this.organization)
    console.log("debug")
    if (window.sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign({}, this.$store.state, JSON.parse(window.sessionStorage.getItem('store')))
      )
    }

    window.addEventListener('beforeunload', () => {
      window.sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    this.$nextTick(() => {
      const menuList = this.$store.getters.getMenuList
      const list = ['系统首页', '薪资管理']
      let menuResult = []

      console.log()
      this.$store.commit('setMenuList', this.$store.getters.routeList)
    })
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  // background-color: #f5f5f5;
}

.box-card {
  margin: 15px;
}

.el-container {
  height: 100% !important;
}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
}

.app-aside {
  width: 198px !important;
  background-color: #304156;
  color: #fff;
  text-align: center;
  height: 100% !important;
  border-right: 1px solid #ccc;
  box-sizing: border-box;
  overflow-y: auto;
}
.app-el-header-logo {
  width: 196px !important;
  background: #304156;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}

.el-main {
  color: #333;
  height: 100%;
  padding: 0 !important;
  width: calc(100% - 200px);
}

body>.el-container {
  margin-bottom: 40px;
}

.app-el-header {
  display: flex;
  padding: 0 !important;

  &-logo,
  &-login {
    width: 200px;
    img {
      width: 130px;
      margin-top: 5px;
    }
  }
  .app-el-header-login {
    width: 400px;
    display: flex;
    // align-items: center;
    justify-content: center;
    div {
      flex:1 ;
      span {
        display: inline-block;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
  }

  &-tab {
    width: calc(100% - 600px);
  }

  &-login {
    display: flex;
    flex-direction: column;
    background-color: #7dcde4;
    padding-left: 10px;
    h3 {
      display: flex;
      font-size: 14px;
      color: #fff;
      align-items: center;
      margin-top: 5px;
      justify-content: space-between;
      margin-bottom: 5px;
      padding-right: 30px;
      >div {
        display: flex;
        align-items: center;
      }
    }
    // p {
      // font-size: 12px;
      // color: #fff;
      // display: flex;
      // width: 100%;
      // padding-left: 10px;
    // }
  }
}
</style>
