import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入全局css初始样式
import './assets/css/base.css'
import router from './router'
import axios from './services/index'
import store from './store'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$store = store
Vue.prototype.$axios = axios
Vue.prototype.$toast = ElementUI.Message
const isProd = process.env.NODE_ENV === 'production'
const prefix = isProd ? window.location.host.indexOf('manage-test.xiaodubanxue.com') > -1 ? '//manage-test.xiaodubanxue.com/manage' : '//manage.xiaodubanxue.com/manage' : '//manage-test.xiaodubanxue.com/manage'
axios.defaults.baseURL = '//manage.xiaodubanxue.com/manage'
// axios.defaults.baseURL = 'http://192.168.2.2:8092/manage'
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
