<template>
  <el-tabs class="tab-nav" v-model="editableTabsValue" type="card" editable @tab-click="handleSelectTab"
    @edit="handleTabsEdit">
    <el-tab-pane :key="item.path" v-for="item in editableTabs" :label="item.name" :name="item.path"></el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  data() {
    return {
      editableTabsValue: '',
      editableTabs: []
    }
  },
  mounted() {
    this.editableTabs = this.$store.state.routerRecord
  },
  methods: {
    handleSelectTab({ name }) {
      if (this.$route.path == name) return
      this.$router.push(name)
    },
    handleTabsEdit(targetName, action) {
      const findTabIndex = this.editableTabs.findIndex(item => item.path == targetName)
      if (findTabIndex > -1) {
        this.$store.commit("putRouterRecord", findTabIndex)
      }
      if (targetName == this.$route.path && this.editableTabs.length > 0) {
        this.$router.replace(this.editableTabs[this.editableTabs.length - 1].path)
      }
    }
  },
  watch: {
    '$store.state.routerRecord': {
      handler(newVal, oldVal) {
        this.editableTabs = newVal
      },
      deep: true
    },
    "$route.path": {
      handler(newVal, oldVal) {
        this.editableTabsValue = newVal;
      },
      deep: true
    }
  }
}
</script>

<style lang="less">
.tab-nav {
  background: #fff;
  height: 100%;

  .el-tabs__header {
    margin: 0;
    height: 100%;
    border: none;

    .el-tabs__new-tab {
      display: none;
    }

    .el-tabs__nav-wrap {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
}
</style>