<template>
    <div class="menu-item">
        <template v-for="(item, index) in this.menuList">
            <div :key="index" v-if="!item.meta || (item.meta && !item.meta.hideMenu)">
                <el-submenu v-if="item.children && item.children.length > 0" :index="item.path">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.name}}</span>
                    </template>
                    <Menu :menuList="item.children"></Menu>
                </el-submenu>
                <el-menu-item v-else :index="item.path" :key="item.path">
                    <i :class="item.icon"></i>
                    <span>{{item.name}}</span>
                </el-menu-item>
            </div>
        </template>
    </div>
</template>
 
<script>
export default {
    name: "Menu",
    props: {
        menuList: {
            type: Array,
            default() {
                return [];
            }
        }
    }
};
</script>
<style lang="less">
.dis-active-span {
    margin-left: 20px;
    font-size: 15px !important;
}

.menu-item {
    /* 菜单栏选中后背景色 */
    .el-menu-item,
    .el-submenu {
        color: #333333;
        text-align: left;
        // i {
        //     color: #606265;
        // }
    }
}
</style>