<template>
  <el-menu class="menu" :unique-opened="true" :default-active="defaultIndex" text-color="#bfcbd9" router
    @select="handleSelectMenu" active-text-color="#47A6FF" background-color="#304156">
    <Menu :menuList="menuList"></Menu>
  </el-menu>
</template>

<script>
import Menu from "@/components/Menu/Item.vue";
export default {
  components: {
    Menu
  },
  data() {
    return {
      menuList: [],
      defaultIndex: "",
      adminUser: JSON.parse(sessionStorage.getItem('adminUser')),
      findMenu: ''
    };
  },
  methods: {
    handleSelectMenu(path) {
      if (path == '/') return
      this.findItem(this.menuList, path)
      this.$store.commit("setRouterRecord", this.findMenu)
      this.findMenu = ''
    },

    findItem(list, path) {
      if (this.findMenu) return
      list.forEach(item => {
        if (item.path == path) {
          this.findMenu = item
          return
        }

        if (item.children && item.children.length > 0) {
          this.findItem(item.children, path)
        }
      })
    }
  },

  mounted() {
    this.defaultIndex = this.$route.path;
    this.menuList = this.$store.getters.routeList;
    console.log('this.menuList',this.menuList)
    let roleFinance = []
    if (this.adminUser && this.adminUser.role && this.adminUser.role == 2) {
      let arr1 = this.menuList.filter((item)=>item.name == '薪资管理')
      let arr2 = this.menuList.filter((item)=>item.name == '数据统计')
      arr1[0].children.splice(0,1)
      arr2[0].children.splice(0,4)
      arr2[0].children.splice(1,2)
      roleFinance = [...arr2,...arr1]
      this.menuList = roleFinance
      return
    }
    const organization = JSON.parse(sessionStorage.getItem('organization'))
    if ((organization && organization.schoolType && (organization.schoolType == 6))) {
      this.menuList.forEach((item,index)=>{
        if (item.children) {
          item.children.forEach((cItem,cIndex)=>{
            if (cItem.name == '机构升级申请') {
              this.menuList[index].children.splice(cIndex,1)
            }
          })
        }
      })
    }
    // 总部及服务部
    if (organization && (organization.schoolType !== 0 && organization.schoolType !== 6)) {
     this.menuList.forEach((item,index)=>{
        if (item.name == '薪资管理') {
          this.menuList.splice(index,1)
        }
      })
    }
    // 课耗收入战略合伙人-创业合伙人
    if (organization && (organization.schoolType === 2 || organization.schoolType === 6)) {
      this.menuList.forEach((item,index)=>{
        if (item.children) {
          item.children.forEach((cItem,cIndex)=>{
            if (cItem.name == '课耗收入记录') {
              this.menuList[index].children.splice(cIndex,1)
            }
          })
        }
      })
    }
    // 课耗收入记录总部
    if (organization && (organization.schoolType !== 0 || organization.schoolType === 1)) {
      this.menuList.forEach((item,index)=>{
        if (item.children) {
          item.children.forEach((cItem,cIndex)=>{
            if (cItem.name == '课耗收入') {
              this.menuList[index].children.splice(cIndex,1)
            }
          })
        }
      })
    }
    if (organization && organization.schoolType != 0) {
      this.menuList.forEach((item,index)=>{
        if (item.children) {
          item.children.forEach((cItem,cIndex)=>{
            if (cItem.name == '退费审批') {
              this.menuList[index].children.splice(cIndex,1)
            }
            if (cItem.name == '俱乐部管理') {
              this.menuList[index].children.splice(cIndex,1)
            }
          })
        }
      })
    }

  },
  watch: {
    "$route.path": {
      handler(newVal, oldVal) {
        this.defaultIndex = newVal;
      },
      deep: true
    }
  }
};
</script>

<style lang="less">
// .el-menu {
//   background: none !important;
//   width: 100% !important;

//   .el-menu-item {
//     color: rgb(191, 203, 217) !important;
//     &:hover,
//     &:focus {
//       background: #263445;
//     }

//     &.is-active {
//       background: #1f2d3d;
//       color: #fff!important;
//     }
//     .el-menu-item.is-active i {
//       color: #fff;
//     }
//   }
//   .el-submenu__title:hover {
//      background: rgb(245, 245, 245);
//   }
// }
</style>