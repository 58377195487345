import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from './store'
const routes = store.getters.routeList
console.log('store.state.menuList', store.getters.getMenuList)
const router = new VueRouter({
  mode: 'hash',
  routes
})
router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem('token')

  if (to.path !== '/login' && !token && to.path !== '/printWord') {
    next({ path: '/login' })
    return
  }
  if (token && to.path == '/login') {
    return next('/')
  }
  next()
})
export default router